import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'nuka-carousel'
import classes from './popular-carousel.module.scss'
import { carouselNext, renderDots, carouselPrev } from './index'
import styles from '../cards/general.module.scss'

const PopularCarousel = ({ children }) => {
  const mobile = () => {
    if (typeof window !== 'undefined') {
      if (window.outerWidth < 768) {
        return true
      }
    }
    return false
  }

  const controlContainerStyles = (key) => {
    let position = {}
    switch (key) {
      case 'CenterLeft':
        position = {
          left: '1rem',
          top: mobile() ? '30%' : '50%',
        }
        break
      case 'CenterRight':
        position = {
          right: '1rem',
          top: mobile() ? '30%' : '50%',
        }
        break
      case 'BottomCenter':
        position = {
          bottom: '1rem',
        }
        break
    }
    return position
  }

  const localRender = (props) => {
    return renderDots({
      ...props,
      dotClass: classes.dots,
      activeClass: classes.activeDot,
    })
  }

  const localNext = ({
    nextSlide,
    currentSlide,
    slideCount,
    slidesToScroll,
  }) => {
    return carouselNext({
      nextSlide,
      currentSlide,
      slideCount,
      slidesToScroll,
      buttonClass: 'carousel-button',
    })
  }

  const localPrev = ({ previousSlide, currentSlide }) => {
    return carouselPrev({
      previousSlide,
      currentSlide,
      buttonClass: 'carousel-button',
    })
  }

  return (
    <Carousel
      renderCenterLeftControls={localPrev}
      renderCenterRightControls={localNext}
      // getControlsContainerStyles={controlContainerStyles}
      // renderBottomCenterControls={localRender}
      enableKeyboardControls
      renderBottomCenterControls={null}
    >
      {children}
    </Carousel>
  )
}
PopularCarousel.propTypes = {
  children: PropTypes.node.isRequired,
}
export default PopularCarousel
