import React from 'react'
import ChevronCircle from '../svg/ChevronCircle.svg'

const getDotIndexes = (
  slideCount,
  slidesToScroll,
  slidesToShow,
  cellAlign,
  scrollMode
) => {
  const dotIndexes = []
  let lastDotIndex = slideCount - slidesToShow

  switch (cellAlign) {
    case 'center':
    case 'right':
      lastDotIndex += slidesToShow - 1
      break
  }

  if (lastDotIndex < 0) {
    return [0]
  }

  for (let i = 0; i < lastDotIndex; i += slidesToScroll) {
    dotIndexes.push(i)
  }

  if (cellAlign === 'left' && scrollMode === 'page') {
    lastDotIndex = Math.floor(
      slideCount - (slideCount % slidesToShow || slidesToShow)
    )
  }

  dotIndexes.push(lastDotIndex)
  return dotIndexes
}
export const renderDots = (props) => {
  const indexes = getDotIndexes(
    props.slideCount,
    props.slidesToScroll,
    props.slidesToShow,
    props.cellAlign,
    props.scrollMode
  )
  let i = 0
  return (
    <ul className={props.dotClass}>
      {indexes.map((index) => {
        i++
        const buttonProps = {
          key: index,
          onClick: props.goToSlide.bind(null, index),
          className: '',
          'aria-label': 'page ' + i,
        }

        let isActive = false
        if (props.currentSlide === 0 && index === 0) {
          isActive = true
        } else {
          if (props.currentSlide === index) {
            isActive = true
          }
        }

        if (isActive) {
          // buttonProps.className = 'active'
          buttonProps['aria-current'] = true
        }
        return (
          <li key={index} className={isActive ? props.activeClass : ''}>
            <button {...buttonProps} />
          </li>
        )
      })}
    </ul>
  )
}
export const carouselNext = (props) => {
  const buttonProps = {
    onClick: props.nextSlide,
    'aria-label': 'next',
    disabled: false,
    className: props.buttonClass,
  }
  if (props.currentSlide === props.slideCount - props.slidesToScroll) {
    buttonProps.disabled = true
    buttonProps['aria-disabled'] = true
  }
  return (
    <button {...buttonProps}>
      <ChevronCircle />
    </button>
  )
}
export const carouselPrev = (props) => {
  const buttonProps = {
    onClick: props.previousSlide,
    'aria-label': 'previous',
    disabled: false,
    className: props.buttonClass,
    style: { transform: 'rotate(-180deg)' },
  }
  if (props.currentSlide === 0) {
    buttonProps.disabled = true
    buttonProps['aria-disabled'] = true
  }
  return (
    <button {...buttonProps}>
      <ChevronCircle />
    </button>
  )
}
