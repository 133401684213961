import Grid from '@material-ui/core/Grid'
import classes from '../pages/home.module.scss'

import Stamp from '../svg/WTStamp.svg'
import FeelGood from '../svg/feel-good.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import Button from '../button'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'next-i18next'
import { useQuery } from '@apollo/client'
import { SEARCH_TAGS } from '../../lib/queries'
import Popup from '../popup'
import { useRouter } from 'next/router'
import styles from './tag.module.scss'
import PropTypes from 'prop-types'
import { SearchContext } from '../../lib/context/search-context'
import config from '../../config'

const TagSearch = ({ content }) => {
  const { searchParams, setSearchParameters } = useContext(SearchContext)
  const { t, i18n } = useTranslation()
  // const [cookies, setCookie] = useCookies(['searchparams'])
  const [selectedType, setSelectedType] = useState({
    name: '',
    id: 0,
  })
  const [selectedArea, setSelectedArea] = useState({
    name: '',
    id: 0,
  })
  const [selectedDuration, setSelectedDuration] = useState({
    name: '',
    id: 0,
  })
  const [typePopup, setTypePopup] = useState(false)
  const [areaPopup, setAreaPopup] = useState(false)
  const [durationPopup, setDurationPopup] = useState(false)
  const router = useRouter()
  const typeParent = useRef(null)
  const areaParent = useRef(null)
  const durationParent = useRef(null)
  // const { loading, error, data } = useQuery(SEARCH_TAGS, {
  //   variables: {
  //     locale: i18n.language,
  //   },
  // })
  useEffect(() => {
    if (
      content &&
      content.hasOwnProperty('areas') &&
      content.areas.length > 0
    ) {
      setSelectedArea({
        name: content.areas[0].name,
        id: content.areas[0].id,
      })
    }
    if (
      content &&
      content.hasOwnProperty('durations') &&
      content.durations.length > 0
    ) {
      setSelectedDuration({
        name: content.durations[0].name,
        id: content.durations[0].id,
      })
    }
    if (
      content &&
      content.hasOwnProperty('types') &&
      content.types.length > 0
    ) {
      setSelectedType({
        name: content.types[0].name,
        id: content.types[0].id,
      })
    }
  }, [content])

  const handleSubmit = () => {
    setSearchParameters(
      {
        ...searchParams,
        filterArea: [selectedArea],
        filterType: [selectedType],
        filterDuration: [selectedDuration],
      },
      0,
      config.cookieOptions
    )
    router.push('/stays')
  }
  // if (loggggading || error) return <React.Fragment />

  return (
    <Grid container spacing={8} justify={'center'}>
      <Grid item xs={12} sm={6} md={5} lg={4}>
        <div className={`wellness-holiday ${classes.wellnessHoliday}`}>
          <h2>
            {content?.heading
              ? content.heading
              : 'Discover your dream wellness holiday'}
          </h2>
          <Stamp />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={7} lg={6}>
        <div className={classes.wellnessHolidayOptions}>
          <FeelGood />
          <div>
            {content?.typeText ? content.typeText : "I'm looking for a"}{' '}
            <div>
              <span onClick={() => setTypePopup(true)} ref={typeParent}>
                {selectedType.name} <FontAwesomeIcon icon={faChevronDown} />
              </span>
              {typePopup && (
                <Popup
                  onClose={() => {
                    setTypePopup(false)
                  }}
                  style={{
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '200%',
                  }}
                  position={'center'}
                  parent={typeParent}
                >
                  <ul>
                    {content.types.map((filter, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => {
                            setTypePopup(false)
                            setSelectedType({
                              name: filter.name,
                              id: filter.id,
                            })
                          }}
                          className={styles.tagPopupOption}
                        >
                          {filter.name}
                        </li>
                      )
                    })}
                  </ul>
                </Popup>
              )}
            </div>
            <br />
            {content?.whereText ? content.whereText : 'in the'}{' '}
            <div>
              <span onClick={() => setAreaPopup(true)} ref={areaParent}>
                {selectedArea.name} <FontAwesomeIcon icon={faChevronDown} />
              </span>
              {areaPopup && (
                <Popup
                  onClose={() => {
                    setAreaPopup(false)
                  }}
                  style={{
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '200%',
                  }}
                  position={'center'}
                  parent={areaParent}
                >
                  <ul>
                    {content.areas.map((filter, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => {
                            setAreaPopup(false)
                            setSelectedArea({
                              name: filter.name,
                              id: filter.id,
                            })
                          }}
                          className={styles.tagPopupOption}
                        >
                          {filter.name}
                        </li>
                      )
                    })}
                  </ul>
                </Popup>
              )}
            </div>
            <br />
            {content?.durationText
              ? content.durationText
              : 'where I can stay'}{' '}
            <div>
              <span onClick={() => setDurationPopup(true)} ref={durationParent}>
                {selectedDuration.name} <FontAwesomeIcon icon={faChevronDown} />
              </span>
              {durationPopup && (
                <Popup
                  onClose={() => {
                    setDurationPopup(false)
                  }}
                  style={{
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '200%',
                  }}
                  position={'center'}
                  parent={durationParent}
                >
                  <ul>
                    {content.durations.map((filter, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => {
                            setDurationPopup(false)
                            setSelectedDuration({
                              name: filter.name,
                              id: filter.id,
                            })
                          }}
                          className={styles.tagPopupOption}
                        >
                          {filter.name}
                        </li>
                      )
                    })}
                  </ul>
                </Popup>
              )}
            </div>
          </div>
          <Button color="green" width={'160px'} onClick={handleSubmit}>
            {content?.buttonText ? content.buttonText : 'Take me away'}
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}
TagSearch.propTypes = {
  content: PropTypes.object,
}
export default TagSearch
