import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import Link from 'next/link'
import Button from '../button'
import VertDiv from '../svg/VertDiv.svg'
import styles from './search-bar.module.scss'
import WTDatePicker from './date-picker'
import Popup from '../popup'
import Chevron from '../svg/Chevron.svg'
import PlusCircle from '../svg/PlusCircle.svg'
import { useTranslation } from 'next-i18next'
import GuestsPopup from '../popups/guests'
import { useCookies } from 'react-cookie'
import { useRouter } from 'next/router'
import { SearchContext } from '../../lib/context/search-context'
import config from '../../config'
import { buildQueryString } from '../../lib/helpers'

const SearchBar = (props) => {
  const router = useRouter()
  const { alt, isMobile, filterTypes } = props
  const { t, i18n } = useTranslation()
  const [guestsPopup, setGuestsPopup] = useState(false)
  const [numGuests, setNumGuests] = useState(2)
  const [numRooms, setNumRooms] = useState(1)
  const { searchParams, setSearchParameters } = useContext(SearchContext)
  const [params, setParams] = useState(searchParams)
  const [type, setType] = useState('Everything')
  const [selectType, setSelectType] = useState(false)
  const [cookies, setCookie] = useCookies(['searchparams'])

  const handleTypeChange = (fType) => {
    setParams({ ...params, filterType: [fType] })
  }

  const updateGuests = (value) => {
    setParams({
      ...params,
      guests: value,
    })
    let newGuests = 0
    setNumRooms(value.length)
    value.forEach((guests, i) => {
      newGuests += guests.paxes.length
    })
    setNumGuests(newGuests)
  }

  const handleSubmit = () => {
    setSearchParameters(params, 0, config.cookieOptions)
    buildQueryString(params)
    router
      .push({
        pathname: '/search',
        query: buildQueryString(params),
      })
      .then(() => {
        window.scroll(0, 0)
      })
  }

  return (
    <>
      <div className="search-bar animated fadeInUp animatedFadeInUp delay-1">
        <div className={'title'}>
          <h3>
            <span>{t('find-your-new')}</span>
          </h3>
          <Chevron />
        </div>
        <div className={'field select'}>
          <div>
            <span className={'label'}>Show Me:</span>
            <div className="selection">
              <h3 onClick={() => setSelectType(!selectType)}>
                {type} <FontAwesomeIcon icon={faChevronDown} />
              </h3>
            </div>
            {selectType && (
              <Popup
                onClose={() => setSelectType(!selectType)}
                style={{
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 285,
                }}
                position={'center'}
              >
                <ul className={'type-select'}>
                  <li
                    onClick={() => {
                      setType('Everything')
                      handleTypeChange([])
                      setSelectType(!selectType)
                    }}
                    className={type === 'Everything' ? 'selected' : ''}
                  >
                    Everything
                  </li>
                  {filterTypes.map((filterType, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => {
                          setType(filterType.name)
                          handleTypeChange(filterType)
                          setSelectType(!selectType)
                        }}
                        className={type === filterType.name ? 'selected' : ''}
                      >
                        {filterType.name}
                      </li>
                    )
                  })}
                </ul>
              </Popup>
            )}
          </div>
        </div>
        <div className={'field spacer'}>
          <VertDiv />
        </div>
        <div className={'field'}>
          <WTDatePicker
            onChange={(dates) => {
              setParams({
                ...params,
                checkinDate: dates.startDate,
                checkoutDate: dates.endDate,
              })
            }}
          />
        </div>
        <div className={'field spacer'}>
          <VertDiv />
        </div>
        <div className={'field guests'}>
          <div>
            <span className={'label'}>Guests &amp; Rooms:</span>
            <div className={'guests-input'}>
              <h3
                onClick={() => {
                  setGuestsPopup(!guestsPopup)
                }}
              >
                {numGuests > 0 ? numGuests : 'Add'} Guests, {numRooms} Room
                {numRooms > 1 && 's'}
              </h3>
            </div>
          </div>
          {guestsPopup && (
            <GuestsPopup
              popupState={setGuestsPopup}
              guests={params.guests}
              handleChange={updateGuests}
            />
          )}
        </div>
        <div className={'field spacer'}>
          <VertDiv />
        </div>
        <div className={'field actions'}>
          <Button
            color={'red'}
            onClick={handleSubmit}
            width={isMobile ? '100%' : '138px'}
          >
            {t('common:search').toUpperCase()}
          </Button>
        </div>
      </div>
    </>
  )
}
SearchBar.propTypes = {
  alt: PropTypes.bool,
  isMobile: PropTypes.bool,
  filterTypes: PropTypes.array,
}
export default SearchBar
