import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'

import styles from './general.module.scss'
import Button from '../button'
import UserSvg from '../svg/User.svg'
import ClockSvg from '../svg/Clock.svg'
import Chevron from '../svg/Chevron.svg'
import { useTranslation } from 'next-i18next'
import { CurrencyContext } from '../../lib/context/CurrencyContext'
import {
  calculateMarkup,
  formatAmountForDisplay,
} from '../../utils/stripe-helpers'
import { SettingsContext } from '../../lib/context/SettingsContext'
import { useModal } from '../../lib/context/modal-context'
import OverviewModal from '../popups/overview-modal'
import Image from 'next/image'
import WishlistFavourite from './favourite'
import WTFavourite from '../svg/WTFavourite.svg'
import HotelGuests from '../svg/HotelGuests.svg'
import PrivateUse from '../svg/private.svg'
import IslandResident from '../svg/IslandResidents.svg'
import Community from '../svg/Rating-Community.svg'
import Gold from '../svg/Rating-Gold.svg'
import Green from '../svg/Rating-Green.svg'
import Heritage from '../svg/Rating-Heritage.svg'
import Shining from '../svg/Rating-Shining.svg'
import Visionary from '../svg/Rating-Visionary.svg'

const ExperienceCard = (props) => {
  const { t, i18n } = useTranslation()
  const { currency } = useContext(CurrencyContext)
  const { settings } = useContext(SettingsContext)
  const { setModal, modal } = useModal()
  const { gallery, small, experience, landscape } = props
  if (!experience) return <React.Fragment />

  const showPopup = () => {
    setModal({
      title: experience.name,
      content: (
        <OverviewModal
          description={experience.overview}
          images={gallery}
          perNight={0}
          title={experience.name}
        />
      ),
    })
  }
  return (
    <>
      <div
        id={experience.slug}
        className={`card experience ${styles.card}
      ${gallery && gallery.length > 0 ? styles.gallery : ''}
      ${props.hasOwnProperty('small') ? styles.small : ''}
      ${
        landscape
          ? `landscape ${styles.landscape}`
          : `vertical ${styles.vertical}`
      }`}
      >
        {(experience.favourite !== '' || experience.rating !== '') && (
            <div className={styles.favourite}>
              {experience.favourite === 'Favourite' && <WTFavourite />}
              {experience.favourite === 'Residents' && <IslandResident />}
              {experience.favourite === 'Guests' && <HotelGuests />}
              {experience.favourite === 'Private' && <PrivateUse />}
              {experience.rating === 'Community' && <Community />}
              {experience.rating === 'Gold' && <Gold />}
              {experience.rating === 'Green' && <Green />}
              {experience.rating === 'Heritage' && <Heritage />}
              {experience.rating === 'Shining' && <Shining />}
              {experience.rating === 'Visionary' && <Visionary />}
            </div>
          )}
        {gallery && gallery.length === 1 && (
          <div className={styles.image}>
            <Image
              src={gallery[0].image.url}
              alt={gallery[0].image.alternativeText}
              layout={'fixed'}
              width={350}
              height={260}
              objectFit={'cover'}
              placeholder="blur"
              blurDataURL={gallery[0].image.url}
              loading='lazy'
            />
            {/*<img src={`${gallery[0].image.url}`} alt="" />*/}
          </div>
        )}
        {gallery && gallery.length > 1 && (
          <div className={`gallery ${styles.gallery}`}>{/* @ts-ignore */}
            <Carousel
              slidesToScroll={1}
              slidesToShow={1}
              renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                <button
                  className={`${styles.chevronLeft} ${
                    currentSlide === 0 ? styles.disabled : ''
                  }`}
                  onClick={previousSlide}
                >
                  <Chevron />
                </button>
              )}
              renderCenterRightControls={({
                nextSlide,
                currentSlide,
                slideCount,
              }) => (
                <button
                  className={`${styles.chevronRight} ${
                    currentSlide === slideCount - 1 ? styles.disabled : ''
                  }`}
                  onClick={nextSlide}
                >
                  <Chevron />
                </button>
              )}
              enableKeyboardControls
              cellSpacing={2}
              renderBottomCenterControls={null}
              className={styles.slider}
            >
              {gallery.map((image, i) => {
                return (
                  <div className={styles.image} key={i}>
                    {image.image &&
                      image.image.formats &&
                      image.image.formats.hasOwnProperty('medium') && (
                        <Image
                          src={image.image.formats.medium.url}
                          alt={image.image.alternativeText}
                          layout={'responsive'}
                          width={750}
                          height={500}
                          placeholder="blur"
                          blurDataURL={image.image.formats.medium.url}
                          loading='lazy'
                        />
                      )}
                    {image.image &&
                      (!image.image.formats ||
                        !image.image.formats.hasOwnProperty('medium')) && (
                        <Image
                          src={image.image.url}
                          alt=""
                          layout={'responsive'}
                          width={750}
                          height={500}
                          placeholder="blur"
                          blurDataURL={image.image.url}
                          loading='lazy'
                        />
                      )}
                  </div>
                )
              })}
            </Carousel>
          </div>
        )}

        <div className={`card-inner ${styles.inner}`}>
          <div className={'card-inner-wrapper'}>
            {!experience.pageTitle && (
              <>
                <span className={styles.tagline}>
                  {experience.location.city}
                </span>
                <h2 className={styles.smallerHeading}>{experience.name}</h2>
              </>
            )}
            {experience.pageTitle && (
              <>
                <span className={styles.tagline}>
                  {experience?.pageTitle?.smallText}
                </span>
                <h2 className={'line-clamp-3'}>
                  {experience?.pageTitle?.largeText}
                </h2>
              </>
            )}
            {!small && (
              <div className={`flex-grow ${styles.innerMiddle}`}>
                <div>
                  <p
                    className={
                      landscape
                        ? 'line-clamp-2 lg:line-clamp-3'
                        : 'line-clamp-2'
                    }
                  >
                    {experience.standfirst}
                  </p>
                  <span
                    className="-top-[1.5rem] relative font-semibold text-blue cursor-pointer"
                    onClick={showPopup}
                  >
                    {t('read-more')}
                  </span>
                </div>
                <div
                  className={`${styles.properties} ${styles.propertiesNoSpread}`}
                >
                  {experience.duration && experience.duration !== '' && (
                    <>
                      <ClockSvg />
                      {experience.duration}
                    </>
                  )}
                  {experience.capacity && experience.capacity !== '' && (
                    <>
                      <UserSvg />
                      Max {experience.capacity} people
                    </>
                  )}
                </div>
              </div>
            )}
            <div className={styles.footer}>
              <div>
                <span className={styles.label}>From</span>
                <span className={styles.price}>
                  {formatAmountForDisplay(
                    calculateMarkup(
                      experience.basePrice,
                      settings.baseMarkup,
                      experience.markup
                    ),
                    currency,
                    settings.exchangeRate
                  )}
                </span>{' '}
                {experience.pricePer && <>/ {experience.pricePer.text}</>}
              </div>

              {!small && (
                <div className={styles.actions}>
                  <WishlistFavourite type="experience" id={experience.id} />
                  <Button
                    size="small"
                    color="green"
                    href={`/experiences/${experience.slug}`}
                    className={'cta-event-trigger'}
                  >
                    Explore
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
ExperienceCard.propTypes = {
  gallery: PropTypes.array,
  small: PropTypes.bool,
  experience: PropTypes.object,
  landscape: PropTypes.bool,
}
export default ExperienceCard
