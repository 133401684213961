import { makeStyles } from '@material-ui/core/styles'

const BlogCarouselStyles = makeStyles((theme) => ({
  slider: {
    marginBottom: 45,
  },
  carouselButton: {
    fontSize: 48,
    lineHeight: 0,
    display: 'block',
    width: 48,
    height: 48,
    padding: 0,
    zIndex: 2,
    cursor: 'pointer',
    color: 'rgb(0, 36, 63)',
    border: 'none',
    outline: 'none',
    background: 'transparent',
    '& svg': {
      width: 48,
      height: 48,
      transition: 'transform 0.2s ease-out',
    },
    '&:hover, &:focus': {
      color: 'rgb(0, 36, 63)',
      outline: 'none',
      background: 'transparent',
    },
    '&:hover svg': {
      transform: 'scale(1.1)',
    },
    '&[disabled]': {
      opacity: 0.25,
      cursor: 'default',
    },
    '&[disabled]:hover svg': {
      transform: 'scale(1)',
    },
  },
  dots: {
    width: '100%',
    padding: 0,
    margin: 0,
    listStyle: 'none',
    textAlign: 'center',
    display: 'none',
    height: 12,
    [theme.breakpoints.up(768)]: {
      display: 'flex',
      flexDirection: 'row',
    },
    '& li': {
      position: 'relative',
      width: 12,
      height: 12,
      margin: '0 8px',
      cursor: 'pointer',
      '& button': {
        opacity: 0,
        width: 12,
        height: 12,
        position: 'absolute',
        left: 0,
        top: 0,
      },
      '&:before': {
        display: 'block',
        content: '""',
        fontSize: 0,
        lineHeight: 0,
        width: 6,
        height: 6,
        cursor: 'pointer',
        color: 'transparent',
        border: '2px solid transparent',
        borderRadius: '50%',
        outline: 'none',
        background: '#333333',
        opacity: 1,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
    },
  },
  activeDot: {
    '&:before': {
      border: '2px solid #333333 !important',
      background: 'transparent !important',
      width: '12px !important',
      height: '12px !important',
    },
  },
  card: {
    // maxWidth: 326,
    minHeight: 442,
  },
  cardSmall: {
    '& img': {
      transform: 'scale(0.8)',
    },
  },
  cardInner: {
    position: 'relative',
    '& > img': {
      display: 'block',
      width: '100%',
    },
  },
  cardContent: {
    padding: theme.spacing(0, 2, 1, 2),
    [theme.breakpoints.up(768)]: {
      padding: theme.spacing(0, 3, 1, 3),
    },
    '& p': {
      margin: '1rem 0',
      fontSize: 12,
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
    '& .standfirst': {
      fontWeight: 400,
    },
    '& h2': {
      margin: '1rem 0',
    },
    '& a': {
      margin: '1rem 0',
      // fontSize: 12,
      color: 'rgb(0, 36, 63)',
      fontWeight: 600,
    },
    '& hr': {
      border: 'none',
      backgroundColor: '#979797',
      margin: '1rem 0',
      height: 1,
    },
  },
  tags: {
    '& span': {
      display: 'inline-block',
      color: theme.palette.secondary.main,
      fontSize: 8,
      backgroundColor: 'rgba(123,202,210,0.3)',
      borderRadius: 12,
      padding: '.5rem',
      lineHeight: '1',
    },
  },
  white: {
    backgroundColor: 'white',
  },
  steppedBlue: {
    position: 'relative',
    paddingBottom: theme.spacing(5),
    '&:before': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '100%',
      height: 'calc(100% - 143px)',
      top: 75,
      left: 0,
      backgroundColor: '#e4f4f6',
    },
    '& > *': {
      position: 'relative',
    },
  },
  dropdown: {
    '& h3': {
      position: 'absolute',
      top: -10,
      left: 0,
      width: '100%',
    },
  },
}))
export default BlogCarouselStyles
