import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faSun } from '@fortawesome/pro-light-svg-icons'
import styles from './stats.module.scss'
import Reveal from 'react-reveal/Reveal'
import Image from 'next/image'

const Stats = (props) => {
  const { data, isMobile } = props
  const currentTimezone = new Date().toLocaleString('en-US', {
    timeZone: 'Europe/Madrid',
  })
  const currentTime = new Date(currentTimezone)
  let temp = 0
  let forecast = ''
  let code = ''
  if (isMobile) {
    return <></>
  }
  if (data.hasOwnProperty('main')) {
    temp = Math.round(parseFloat(data.main.temp))
  }
  if (data.hasOwnProperty('weather')) {
    forecast = data.weather[0].main
    code = data.weather[0].icon
  }
  return (
    <div className="weather-stats animated animatedFadeInUp fadeInUp delay-1">
      <div className={'timezone'}>
        <FontAwesomeIcon icon={faClock} />
        <h4>Local Time</h4>
        <span className={'time'}>{`${currentTime.getHours()}:${
          currentTime.getMinutes() < 10
            ? '0' + currentTime.getMinutes()
            : currentTime.getMinutes()
        } ${currentTime.getHours() >= 12 ? 'PM' : 'AM'}`}</span>
      </div>
      <div className="forecast animated animatedFadeInUp fadeInUp delay-1">
        <Image
          src={`/static/images/weather/${code}.png`}
          alt={forecast}
          layout={'fixed'}
          width={24}
          height={24}
          className={'forecast-icon'}
        />
        <h4>Weather</h4>
        <span>
          {temp}&deg; {forecast}
        </span>
      </div>
    </div>
  )
}
Stats.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
}
export default Stats
