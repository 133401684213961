import Carousel from 'nuka-carousel'
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { carouselNext, carouselPrev, renderDots } from './index'
import DestinationsCarouselStyles from './destinations-carousel-styles'
import { useRouter } from 'next/router'
import ApiContext from '../../utils/api-context'
import Link from 'next/link'
import Image from 'next/image'
import { MobileContext } from '../../lib/context/mobile-context'
import config from '../../config'
import { SearchContext } from '../../lib/context/search-context'
import { buildQueryString } from '../../lib/helpers'

const DestinationsCarousel = (props) => {
  const [numSlides, setNumSlides] = useState(1)
  const router = useRouter()
  const { apiUrl, cdnUrl } = useContext(ApiContext)
  const mobile = useContext(MobileContext)
  const { searchParams, setSearchParameters } = useContext(SearchContext)

  const localRender = (props) => {
    return renderDots({
      ...props,
      dotClass: 'seek-dots',
      activeClass: 'active-dots',
    })
  }

  const controlContainerStyles = (key) => {
    let position = {}
    switch (key) {
      case 'CenterLeft':
        position = {
          left: window && window.innerWidth < 981 ? '30%' : '-62px',
          top: window && window.innerWidth < 981 ? '107%' : '50%',
          transform:
            window && window.innerWidth < 981 ? 'none' : 'translateY(-50%)',
        }
        break
      case 'CenterRight':
        position = {
          right: window && window.innerWidth < 981 ? '30%' : '-62px',
          top: window && window.innerWidth < 981 ? '107%' : '50%',
          transform:
            window && window.innerWidth < 981 ? 'none' : 'translateY(-50%)',
        }
        break
      case 'BottomCenter':
        position = {
          bottom: '-2.5rem',
        }
        break
    }
    return position
  }

  const localNext = ({
    nextSlide,
    currentSlide,
    slideCount,
    slidesToScroll,
  }) => {
    if (currentSlide === slideCount - numSlides) {
      return <></>
    }
    return carouselNext({
      nextSlide,
      currentSlide,
      slideCount,
      slidesToScroll,
      buttonClass: 'carousel-button next',
    })
  }

  const localPrev = ({ previousSlide, currentSlide }) => {
    return carouselPrev({
      previousSlide,
      currentSlide,
      buttonClass: 'carousel-button prev',
    })
  }

  useEffect(() => {
    setNumSlides(() => {
      if (typeof window !== 'undefined') {
        if (window.outerWidth > 1300) {
          return 5
        }
        if (window.outerWidth > 1024) {
          return 3
        }
        if (window.outerWidth > 767) {
          return 2
        }
      }
      return 1
    })
  })

  const parseTags = (tags, tab) => {
    setSearchParameters(
      {
        ...searchParams,
        checkinDate: '',
        checkoutDate: '',
        filterType: tags,
      },
      0,
      config.cookieOptions
    )
    router.push(
      `/search?${buildQueryString({
        ...searchParams,
        checkinDate: '',
        checkoutDate: '',
        filterType: tags,
        tab: tab,
      })}`.replace('checkinDate=&checkoutDate=&', '')
    )
  }

  return (
    <Carousel
      slidesToScroll={1}
      slidesToShow={numSlides}
      renderCenterLeftControls={localPrev}
      renderCenterRightControls={localNext}
      renderBottomCenterControls={localRender}
      enableKeyboardControls
      cellSpacing={6}
      dragging={false}
      swiping={false}
      scrollMode="remainder"
    >
      {props.cards.map((card, i) => {
        return (
          <div className={'seek-card'} key={i}>
            <div className={'card-inner'}>
              <Image
                src={card.background_image.formats.small.url}
                alt={card.background_image.alternativeText}
                width={228}
                height={315}
                layout={'fixed'}
                // placeholder="blur"
                // blurDataURL={card.background_image.formats.thumbnail.url}
                loading='lazy'
              />
              {/*<img src={`${card.background_image.url}`} alt="" />*/}
              <div className={'card-content'}>
                <h3>{card.heading}</h3>
                <p>{card.sub_heading}</p>
                <div className={'card-extra'}>
                  <span onClick={() => parseTags(card.filterMatrices, card.defaultTab)}>
                    Explore
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </Carousel>
  )
}
DestinationsCarousel.propTypes = {
  show: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
}
export default DestinationsCarousel
