import Carousel from 'nuka-carousel'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { carouselNext, carouselPrev, renderDots } from './index'
import classes from './testimonials-carousel.module.scss'
import { gql, useQuery } from '@apollo/client'
import ApiContext from '../../utils/api-context'

const TestimonialsHomeCarousel = ({ language, testimonials }) => {
  const { apiUrl, cdnUrl } = useContext(ApiContext)

  const localRender = (props) => {
    return renderDots({
      ...props,
      dotClass: classes.dots,
      activeClass: classes.activeDot,
    })
  }

  const controlContainerStyles = (key) => {
    let position = {}
    switch (key) {
      case 'CenterLeft':
        position = {
          left: 'calc(100% - 104px)',
          top: 'calc(100% + 3rem)',
          transform: 'translateY(0)',
          width: 40,
        }
        break
      case 'CenterRight':
        position = {
          left: 'calc(100% - 46px)',
          top: 'calc(100% + 3rem)',
          transform: 'translateY(0)',
          width: 40,
        }
        break
      case 'BottomCenter':
        position = {
          display: 'none',
          bottom: '-1.75rem',
          left: 0,
          transform: 'translateX(0)',
        }
        break
    }
    return position
  }

  const localNext = ({
    nextSlide,
    currentSlide,
    slideCount,
    slidesToScroll,
  }) => {
    return carouselNext({
      nextSlide,
      currentSlide,
      slideCount,
      slidesToScroll,
      buttonClass: classes.carouselButton,
    })
  }

  const localPrev = ({ previousSlide, currentSlide }) => {
    return carouselPrev({
      previousSlide,
      currentSlide,
      buttonClass: classes.carouselButton,
    })
  }

  const slidesToShow = () => {
    if (typeof window !== 'undefined') {
      if (window.outerWidth > 767) {
        return 2
      }
    }

    return 1
  }

  return (
    <Carousel
      slidesToScroll={1}
      slidesToShow={slidesToShow()}
      renderCenterLeftControls={localPrev}
      renderCenterRightControls={localNext}
      // getControlsContainerStyles={controlContainerStyles}
      renderBottomCenterControls={localRender}
      enableKeyboardControls
      cellSpacing={6}
      className={classes.slider}
    >
      {testimonials.map((testimonial, i) => {
        const tDate = new Date(testimonial.date)
        return (
          <div className={classes.card} key={i}>
            <div className={classes.cardInner}>
              <img src={`${testimonial.avatar.url}`} alt="" />
              <blockquote className={classes.cardQuote}>
                <h3>{testimonial.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: testimonial.quote }} />
                <footer>
                  <p>
                    <strong>{testimonial.name}</strong>
                  </p>
                  <p>
                    {tDate.toLocaleString('default', { month: 'short' })}{' '}
                    {('0' + tDate.getDate()).slice(-2)}
                  </p>
                </footer>
              </blockquote>
            </div>
          </div>
        )
      })}
    </Carousel>
  )
}
TestimonialsHomeCarousel.propTypes = {
  language: PropTypes.string.isRequired,
  testimonials: PropTypes.array,
}
export default TestimonialsHomeCarousel
