import Carousel from 'nuka-carousel'
import React, { useEffect, useState } from 'react'
import { carouselNext, carouselPrev, renderDots } from './index'
import classes from './stays-carousel.module.scss'
import ExperienceCard from '../cards/experience'
import HotelCard from '../cards/hotel'
import PropTypes from 'prop-types'

const StaysCarousel = ({ hotels, experiences }) => {
  const [numSlides, setNumSlides] = useState(1)
  const localRender = (props) => {
    return renderDots({
      ...props,
      dotClass: classes.dots,
      activeClass: classes.activeDot,
    })
  }

  useEffect(() => {
    setNumSlides(() => {
      if (typeof window !== 'undefined') {
        if (window.outerWidth > 1300) {
          return 4
        }
        if (window.outerWidth > 1024) {
          return 3
        }
        if (window.outerWidth > 767) {
          return 2
        }
      }
      return 1
    })
  })

  const controlContainerStyles = (key) => {
    let position = {}
    switch (key) {
      case 'CenterLeft':
        position = {
          left: 'calc(50% - 3.5rem)',
          top: 'calc(100% + 2rem)',
          transform: 'translateY(0)',
          width: 46,
        }
        break
      case 'CenterRight':
        position = {
          right: 'calc(50% - 3.5rem)',
          top: 'calc(100% + 2rem)',
          transform: 'translateY(0)',
          width: 46,
        }
        break
      case 'BottomCenter':
        position = {
          display: 'none',
          bottom: '-1.75rem',
        }
        break
    }
    return position
  }

  const localNext = ({
    nextSlide,
    currentSlide,
    slideCount,
    slidesToScroll,
  }) => {
    // if (currentSlide >= slideCount - numSlides) {
    //   return <></>
    // }
    return carouselNext({
      nextSlide,
      currentSlide,
      slideCount,
      slidesToScroll,
      buttonClass: 'carousel-button',
    })
  }

  const localPrev = ({ previousSlide, currentSlide }) => {
    return carouselPrev({
      previousSlide,
      currentSlide,
      buttonClass: 'carousel-button',
    })
  }

  const carouselItems = [...experiences, ...hotels]
  return (
    <Carousel
      slidesToScroll={numSlides}
      slidesToShow={numSlides}
      renderCenterLeftControls={localPrev}
      renderCenterRightControls={localNext}
      // getControlsContainerStyles={controlContainerStyles}
      renderBottomCenterControls={localRender}
      enableKeyboardControls
      cellSpacing={6}
      wrapAround={carouselItems.length > 4}
    >
      {carouselItems.map((item, i) => {
        return (
          <div className={classes.card} key={i}>
            <div className={classes.cardInner}>
              {item.hasOwnProperty('hotel_ref') && <HotelCard hotel={item} />}
              {!item.hasOwnProperty('hotel_ref') && (
                <ExperienceCard experience={item} gallery={[item.images[0]]} />
              )}
            </div>
          </div>
        )
      })}
    </Carousel>
  )
}
StaysCarousel.propTypes = {
  hotels: PropTypes.array.isRequired,
  experiences: PropTypes.array,
}
export default StaysCarousel
