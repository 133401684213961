import React from 'react'
import styles from './hero.module.scss'
import PropTypes from 'prop-types'

const Hero = ({
  background,
  video = false,
  children,
  isMobile,
  overlay,
  destinations,
  backLink,
}) => {
  return (
    <div
      className={`hero ${overlay ? 'overlay' : ''} ${
        destinations ? 'destinations' : ''
      }`}
    >
      <div className={'hero-inner'}>{children}</div>
      {!video && (
        <div
          className={'image-background'}
          style={{ backgroundImage: `url(${background})` }}
        />
      )}
      {video && background && (
        <div className={'video-background'}>
          <video autoPlay loop muted>
            <source src={`${background}`} type="video/mp4" />
          </video>
        </div>
      )}
      {backLink}
    </div>
  )
}
Hero.propTypes = {
  background: PropTypes.string.isRequired,
  video: PropTypes.bool,
  isMobile: PropTypes.bool,
  overlay: PropTypes.bool,
  destinations: PropTypes.bool,
  backLink: PropTypes.node,
}

export default Hero
