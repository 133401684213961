import React from 'react'

const Loading = () => {
  return (
    <div className={'loading'}>
      <p>Loading the best of</p>
      <div className="loader-wrap">
        <span className="loader" />
      </div>
      <p>Feel Good Mallorca</p>
    </div>
  )
}
export default Loading
