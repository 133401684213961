import PropTypes from 'prop-types'
import classes from './inset-panel.module.scss'
import ContoursLargeSvg from './svg/ContoursLarge.svg'
import Link from 'next/link'
import ReactMarkdown from 'react-markdown'
import React from 'react'
import { i18n } from 'next-i18next'
import WTStamp from './svg/stamp.svg'
import Image from 'next/image'

const InsetPanel = ({ reverse, content, withStamp }) => {
  if (!content) {
    return <React.Fragment />
  }
  return (
    <>
      <div className={classes.contourWrapper}>
        <ContoursLargeSvg />
      </div>
      <div className={classes.insetPanel}>
        <div className={classes.insetPanelContent}>
          {!reverse && (
            <div
              className={classes.insetPanelBackground}
            >
              <Image 
                src="/static/images/placeholders/bespoke-experiences-image.webp"
                alt="Bespoke Experiences"
                loading='lazy'
                layout='fill'
              />
            </div>
          )}
          <div className={classes.insetPanelInner}>
            {withStamp && (
              <div className={classes.insetPanelStamp}>
                <WTStamp />
              </div>
            )}
            <h2 className={`${classes.sectionHeading}`}>
              <span className={classes.tagLarge}>{content.tag}</span>
              {content.heading}
            </h2>
            <div
              className={`${classes.insetPanelStatement} ${classes.columns}`}
              dangerouslySetInnerHTML={{ __html: content.content }}
            />
            <ul className={classes.insetPanelLinks}>
              {content.bespoke_links.map((link, i) => {
                return (
                  <li key={i}>
                    <h4>{link.heading}</h4>
                    <Link href={link.link_url} locale={i18n.language}>
                      {link.link_text}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          {reverse && (
            <div
            className={classes.insetPanelBackground}
            >
              <Image 
                src="/static/images/placeholders/bespoke-experiences-image.webp"
                alt="Bespoke Experiences"
                loading='lazy'
                layout='fill'
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
InsetPanel.propTypes = {
  reverse: PropTypes.bool,
  content: PropTypes.object,
  withStamp: PropTypes.bool,
}
export default InsetPanel
