import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'
import React from 'react'
import { carouselNext, carouselPrev, renderDots } from './index'
import BlogCarouselStyles from './blog-carousel-styles'
import { useTranslation } from 'next-i18next'

const BlogCarousel = (props) => {
  const { children, navPosition, mobile } = props
  const { t, i18n } = useTranslation()
  const classes = BlogCarouselStyles({})
  const localRender = (props) => {
    return renderDots({
      ...props,
      dotClass: classes.dots,
      activeClass: classes.activeDot,
    })
  }

  const controlContainerStyles = (key) => {
    let position = {}
    switch (key) {
      case 'CenterLeft':
        position = {
          left:
            navPosition === 'top' ? 'calc(100% - 108px)' : 'calc(50% - 54px)',
          top:
            navPosition === 'top'
              ? mobile
                ? '100%'
                : '-6rem'
              : 'calc(100% + 54px)',
          transform: 'translateY(0)',
          width: 48,
        }
        break
      case 'CenterRight':
        position = {
          left: navPosition === 'top' ? 'calc(100% - 54px)' : 'calc(50% + 6px)',
          top:
            navPosition === 'top'
              ? mobile
                ? '100%'
                : '-6rem'
              : 'calc(100% + 54px)',
          transform: 'translateY(0)',
          width: 48,
        }
        break
      case 'BottomCenter':
        position = {
          display: 'none',
          bottom: '-1.75rem',
          left: 0,
          transform: 'translateX(0)',
        }
        break
    }
    return position
  }

  const localNext = ({
    nextSlide,
    currentSlide,
    slideCount,
    slidesToScroll,
  }) => {
    return carouselNext({
      nextSlide,
      currentSlide,
      slideCount,
      slidesToScroll,
      buttonClass: classes.carouselButton,
    })
  }

  const localPrev = ({ previousSlide, currentSlide }) => {
    return carouselPrev({
      previousSlide,
      currentSlide,
      buttonClass: classes.carouselButton,
    })
  }

  const slidesToShow = () => {
    if (typeof window !== 'undefined') {
      if (window.outerWidth > 1024) {
        return 3
      }
      if (window.outerWidth > 767) {
        return 2
      }
    }

    return 1
  }

  return (
    <Carousel
      slidesToScroll={1}
      slidesToShow={slidesToShow()}
      renderCenterLeftControls={localPrev}
      renderCenterRightControls={localNext}
      // getControlsContainerStyles={controlContainerStyles}
      renderBottomCenterControls={localRender}
      enableKeyboardControls
      cellSpacing={30}
      className={classes.slider}
    >
      {children}
    </Carousel>
  )
}
BlogCarousel.propTypes = {
  children: PropTypes.node,
  navPosition: PropTypes.any.isRequired,
  mobile: PropTypes.bool,
}
export default BlogCarousel
