import React from 'react'
import Layout from '../components/layout'
import Home from '../components/pages/home'
import { fetchPage, fetchWeather } from '../lib/api'
import { WEATHER_API_KEY } from '../lib/constants'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Meta from '../components/meta'

export default function Index({ weatherJson, data }) {
  return (
    <>
      {data && <Meta {...data} />}
      <Layout>
        <Home weather={weatherJson} />
      </Layout>
    </>
  )
}

export async function getServerSideProps({ locale, req }) {
  const weatherJson = await fetchWeather(WEATHER_API_KEY)
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      weatherJson,
      cookie: req.headers.cookie || '',
      ...(await fetchPage({ page: 'homepage/seo', locale })),
    },
  }
}
